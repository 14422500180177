@import url(https://fonts.googleapis.com/css?family=Cairo);
/* change font when in arabic */

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000000;
    -webkit-transition: background-color 500s ease-in-out 0s;
    transition: background-color 500s ease-in-out 0s;
    /* box-shadow: inset 0 0 20px 20px #23232329; */
}

:lang(ar) {
    font-family: 'Cairo', sans-serif !important;
}

/* main settings */
body {
    font-family: "IBM Plex Sans Arabic";
    font-size: 14px;
}

.temp-business-text-blue{
    color: #1036CE;
    font-weight: 600;
}

.cancellable-text{
    margin-top: 20px;
    text-align: center;
}
a { text-decoration: none;}
a:hover { color: #FF8139;}
.main-wrap {
    max-width: 1210px;
    width: 100%;
}
.dflex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.dflex-bottom {
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.imgenclose1{
    width: 100%;
    height: 100%;
    background-color: #fff;
    object-fit: cover;
}

.imgenclose {
    width: 100%;
    height: 100%;
    /* max-height: 359px; */
    object-fit: cover;
}

.imgencloseReviewProfilePic{
    width: 100%;
    height: 100%;
    border-radius: 50;
    object-fit: cover;
}

.imgencloseReviewProfilePicContainer:lang(en){
    max-width: 120px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 5px;
}

.imgencloseReviewProfilePicContainer:lang(ar){
    max-width: 120px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin-left: 5px;
}


.imgenfit{
    width: 100%;
    height: 100%;
    /* max-height: 359px; */
    object-fit: scale-down;
}

.imgnophoto{
    /* position: relative; */
    font-size:14px;
    margin-top: 20px !important;
    margin: 0 auto;
    font-weight: 800;
    color:rgb(85, 85, 85);
    margin-bottom: 50px;
}

.imgenclosex {
    padding-top: 50px !important;
    margin: 0 auto;
    width: 300px;
    max-width: 300px;
    object-fit: cover;
}

/* color */
.cwhite { color: #FFFFFF; }
.cred { color: #FF5833; }
.cred11 {color: #ff2121;}
.cred2 { color: #FF0000; }
.cgray { color: #AAAAAA; }
.cgray2 { color: #999BA6; }
.cgray3 { color: #999999; }
.cblack { color: #242424; }
.cblack2 { color: #181818; }
.cveryblack { color: #000000; }
.cblue { color: #1036CE; }

/* font size */
.fs12 { font-size: 12px; }
.fs14 { font-size: 14px; }
.fs16 { font-size: 16px; }
.fs18 { font-size: 18px; }
.fs20 { font-size: 20px; }
.fs22 { font-size: 22px; }
.fs30 { font-size: 30px; }

/* font weight */
.fw400 { font-weight: 400;}
.fw500 { font-weight: 500; }
.fw600 { font-weight: 600; }
.fw700 { font-weight: 700; }
.fw800 { font-weight: 800; }

/* cursor */
.cur-pointer { cursor: pointer;}

.support-email {color: #1036CE;}

/* header */
.header-up {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #242424;
}
.lang-dropdown {
    background: transparent;
    border: none;

}
.lang-dropdown:hover,
.lang-dropdown:focus,
.lang-dropdown:active,
.lang-dropdown:active:focus {
    background: transparent;
    border:none;
    box-shadow: none;
}
.header-div #basic-navbar-nav .navbar-nav > a {
    font-weight: 700;
    font-size: 16px;
    color: #242424;
    margin-right: 40px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    text-transform: uppercase;
}
.header-div #basic-navbar-nav .navbar-nav > a.active {
    color: #1036CE;
    border-bottom: 2px solid #1036CE;
}
.btn-getstarted {
    background: #FF5833;
    font-weight: 700;
    color: #FFFFFF;
    padding: 8px 26px;
    border-radius: 0;
    margin-top: -10px;
}
.div-head-circle {
    border: 1px solid #242424;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.div-head-profpic {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    overflow: hidden;
}
.dropdown-prof {
    background: transparent;
    border: none;
    color: #242424;
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
}
.dropdown-prof:hover,
.dropdown-prof:focus,
.dropdown-prof:active,
.dropdown-prof:active:focus {
    background: transparent;
    border:none;
    box-shadow: none;
    color: #242424;
}


/* footer */
.footer-up-div {
    padding-top: 60px;
    padding-bottom: 60px;
    background: #1036CE;
}
.footer-bottom-div {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #FFFFFF;
}
.footer-email {
    background: transparent;
    border: none;
    border-bottom: 1px solid #FFFFFF;
    color: #FFFFFF;
    border-radius: 0;
    padding-left: 0;
    padding-bottom: 15px;
}
.footer-email::placeholder {
    color: #FFFFFF;
    opacity: 1;
}
.footer-email:focus,
.footer-email:active {
    background: transparent;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #FFFFFF;
    color: #FFFFFF;
}



/* homepage */
.banner-div {
    /* background: url('../images/banner.png') no-repeat;
    background-position: center center;
    background-size: cover; */
    background: rgb(20,51,172);
    background: linear-gradient(48deg, rgba(20,51,172,1) 19%, rgba(12,58,240,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1433ac",endColorstr="#0c3af0",GradientType=1);
}
.banner-text-div {
    padding-top: 100px;
    padding-bottom: 100px;
    max-width: 800px;
    width: 100%;
}
.banner-text-div div {
    max-width: 500px;
    width: 100%;
}
.banner-text-div div > .title {
    font-size: 40px;
}
.banner-text-div div > .text {
   width: 90%;
   margin-bottom: 60px;
}
.btn-orang-def {
    background: #FF5833;
    border-radius: 0;
    border: none;
    font-weight: 500;
    color: #FFFFFF;
    padding: 14px 70px;
}
.btn-orang-def:focus,
.btn-orang-def:active,
.btn-orang-def:hover {
    background: #db4f30;
    color: #FFFFFF;
}
.banner-img-slide-div {
    padding-left: 0;
    padding-right: 0;
    position: relative;
}
.banner-img-slide-div .carousel.slide,
.banner-img-slide-div .carousel.slide .carousel-inner {
    height: 280px;
}
.banner-img-slide-div .carousel.slide .carousel-inner .carousel-item,
.banner-img-slide-div .carousel.slide .carousel-inner .carousel-item > img {
    height: 100%;
    /* object-fit: contain; */
}

.carousel-custombtn-div {
    display: flex;

}
.carousel-custombtn-div .btn-back {
    display: flex;
    background: #FFFFFF;
    border-radius: 50%;
    color: #242424;
    font-weight: 500;
    width:50px;
    height: 50px;
    padding: 12px 16px;
}
.carousel-custombtn-div .btn-next {
    display: flex;
    background: #FF5833;
    border-radius: 50%;
    color: #FFFFFF;
    font-weight: 500;
    width:50px;
    height: 50px;
    padding: 12px 16px;
    margin-left: 20px;
}
.carousel-custombtn-div.banner {
    position: absolute;
    bottom: 45px;
    right: 23%;
}

.services-main-div {
    padding-top: 50px;
    padding-bottom: 50px;
}

.serv-per-div {
    padding: 20px 15px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(198, 198, 198, 0.25);
    border-radius: 5px;
    width: 75%;
    margin: 0 auto;
    margin-bottom: 20px;
}
.carousel-custombtn-div.ser {
    margin-top: 30px;
    margin-bottom: 40px;
}
.carousel-custombtn-div.ser .btn-back {
    border: 1px solid #AAAAAA;
}

.services-provided-div {
    padding-top: 100px;
    padding-bottom: 100px;
    background: #1036CE;
}
.ser-pro-desc-div {
    max-width: 700px;
    width: 100%;
    background: url(/static/media/bg-logo.866b0d28.png) no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 95px 15px 15px 15px;
}
.swiper-serpro {
    padding-left: 70px;
    margin-bottom: 40px;
}
.ser-pro-swiper-back {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: 1px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    position: absolute;
    top: -8px;
    left: 0;
}
.ser-pro-swiper-next {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: 1px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 99;

    position: absolute;
    top: -8px;
    right: 0;
}

.hiw-main-div {
    padding-top: 50px;
    padding-bottom: 50px;
}
.hiw-main-content {
    background: #F3F8FF;
}
.hiw-outer-div {
    width: 75%;
    margin: 0 auto;
}
.hiw-per-bot-div {
    z-index: -9999 !important;
    background: #FEFEFE;
    /* box-shadow: inset 0px 4px 14px rgba(0, 0, 0, 0.25); */
    filter: drop-shadow(0 2px 3px rgb(0 0 0 / 0.1)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.1));
    /* box-shadow: 0px 1px 8px rgb(0 0 0 / 0.15); */
    border-radius: 10px;
    padding: 100px 20px 20px;
    min-height: 180px;
}
.hiw-per-top-div {
    background: #FF5833;
    /* box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25); */
    width: 150px;
    height: 150px;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
    top: -76px;
}


/* featured */
.feat-div {
    padding-top: 50px;
    padding-bottom: 50px;
}
.feat-view-more {
    display: block;
    text-align: center;
    margin-top: 25px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #1036CE;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
}


/* login */
.no-header-div {
    padding-top: 100px;
    padding-bottom: 100px;
}

.login-title {
    font-size: 50px;
    margin-top: 70px;
    margin-bottom: 25px;
}
.input-login {
    border: 1px solid #AAAAAA;
    border-radius: 4px;
    height: 50px;
    padding-left: 20px;
}
.btn-sendotp {
    background: #FF5833;
    border-radius: 16px;
    width: 100%;
    padding: 10px 20px;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 20px;
    margin-top: 30px;
}
.btn.btn-sendotp.disabled,
.btn.btn-sendotp:disabled {
    background: #AAAAAA;
    color: #FFFFFF;
}
.go-visitor-text > a {
    border-bottom: 1px solid #1036CE;
}


/* verification */
.veri-input-div {
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
    display: flex;
}
.veri-input-div:lang(ar) {
    direction:LTR;
}
.veriForm-input {
    width: 60px;
    height: 60px;
    border: 1px solid #AAAAAA;
    border-radius: 16px;
    margin-right: 10px;
    font-size: 20px;
    text-align: center;

}
.btn-verification {
    background: #FF5833;
    border-radius: 10px;
    color: #FFFFFF;
    padding: 10px 85px;
    margin-top: 40px;
}
.btn-verification.disabled,
.btn-verification:disabled {
    background: #AAAAAA;
    color: #FFFFFF;
}
.veri-send-text span {
    color: #DFE4FE;
    cursor: pointer;
    text-decoration: line-through;
}
.veri-send-text span.sendcode-active {
    color: #1036CE;
    text-decoration: none;
}

.veri-send-text{
    padding: 5px;
}

.sendcode-active {
    color: #1036CE;
    text-decoration: none;
    cursor: pointer;
}


/* registration */
.reg-info-div .carousel-caption {
    bottom: 11.5rem;
}
.carousel-logo {
    position: absolute;
    top: 6.5rem;
    left: 6.5rem;
}
.reg-input-form-div {
    padding-top: 30px;
    max-width: 520px;
    width: 100%;
}
.reg-photo-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
}
.reg-photo-div {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 1px solid #AAAAAA;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-change-photo {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: #1036CE;
    display: grid;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 37%;
}
.btn-change-photo:hover,
.btn-change-photo:focus,
.btn-change-photo:active {
    background: #1036CE;
}

.reg-input-form {
    border: 1px solid #AAAAAA;
    border-radius: 4px;
    padding-left: 20px;
    height: 48px;
}
.reg-input-form::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #AAAAAA;
    opacity: 1; /* Firefox */
}
.reg-input-form :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #AAAAAA;
}
.reg-input-form::-ms-input-placeholder { /* Microsoft Edge */
    color: #AAAAAA;
}
.reg-input-form:active,
.reg-input-form:focus {
    border: 1px solid #FFFFFF;
    outline: none;
    box-shadow: 0px 2px 8px rgba(24, 24, 24, 0.24);
    border-radius: 4px;
}
.reg-phone-code {
    max-width: 100px;
    width: 100%;
    padding: 0;
    padding-left: 14px;
    border: 1px solid #AAAAAA;
    border-radius: 4px;
}
.reg-phone-code + .reg-input-form {
    border-left: none;
}
.reg-phone-code:active,
.reg-phone-code:focus {
    border: 1px solid #FFFFFF;
    outline: none;
    box-shadow: 0px 2px 8px rgba(24, 24, 24, 0.24);
    border-radius: 4px;
}
.reg-select-form {
    border: 1px solid #AAAAAA;
    border-radius: 4px;
    padding-left: 20px;
    height: 48px;
    color: #AAAAAA;
}
.reg-select-form:active,
.reg-select-form:focus {
    border: 1px solid #FFFFFF;
    outline: none;
    box-shadow: 0px 2px 8px rgba(24, 24, 24, 0.24);
    border-radius: 4px;
}
.reg-checkbox-form {
    width: 250px;
    margin: 0 auto;
}


/* leases */

.product-distance{
    background-color: #a6a6a6;
    color: #FFFFFF;
    border-radius: 14px;
    margin:0.5em;
    padding: 0.5em;
    font-size:12px;
}


.claimTypeLease{
    background-color: #1036CE;
    /* display: inline-block; */
    color: #ffffff;
    padding: 5px;
    /* border-radius: 6px; */
}

.prod-options{
    display: flex;
    position: absolute;
    z-index: 999;
}

.prod-options-icon:lang(en){
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
}

.prod-options-icon:lang(ar){
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
}

.claimIcon{
    /* background-color: #a6a6a6; */
    /* color: #FFFFFF;
    border-radius: 4px;
    margin:0.5em;
    padding: 0.15em;
    font-size:12px; */
    width: 25px;
    height: 25px;
}


.product-claim-type{
    background-color: #a6a6a6;
    color: #FFFFFF;
    border-radius: 4px;
    margin:0.5em;
    padding: 0.15em;
    font-size:12px;
    width: 25px;
    height: 25px;
    /* position: absolute; */
}


/* .product-distance:hover{
    opacity: 1;
    transition:300ms;
} */
.leases-div {
    /* background: 
    linear-gradient(to left, rgba(114, 142, 255, 0.52), rgb(15,40,139, 0.83)),
    url('../images/test.jpg') no-repeat;
    background-position: center center;
    background-size: cover; */
    padding-top: 80px;
    padding-bottom: 80px;
    /* background: rgb(15,40,139);
    background: -moz-linear-gradient(48deg, rgba(15,40,139,1) 19%, rgba(16,54,206,1) 100%);
    background: -webkit-linear-gradient(48deg, rgba(15,40,139,1) 19%, rgba(16,54,206,1) 100%);
    background: linear-gradient(48deg, rgba(15,40,139,1) 19%, rgba(16,54,206,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f288b",endColorstr="#1036ce",GradientType=1); */
    background: rgb(20,51,172);
    background: linear-gradient(48deg, rgba(20,51,172,1) 19%, rgba(12,58,240,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1433ac",endColorstr="#0c3af0",GradientType=1);
}
.product-list-div {
    padding-top: 30px;
    padding-bottom: 70px;
}
.prod-search {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 50px;
}
.prod-search input.input-prodsearch {
    border: 1px solid #AAAAAA;
    border-top-left-radius: 16px !important;
    border-bottom-left-radius: 16px !important;
    padding-left: 55px;
    font-style: normal;
    font-weight: 300;
    color: #AAAAAA;
    font-size: 14px;
    width: 66%;
}

.prod-search input.input-prodsearch::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #AAAAAA;
    opacity: 1; /* Firefox */
}
.prod-search input.input-prodsearch :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #AAAAAA;
}
.prod-search input.input-prodsearch::-ms-input-placeholder { /* Microsoft Edge */
    color: #AAAAAA;
}
.prod-search .select-prodsearch {
    border: 1px solid #AAAAAA;
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    color: #AAAAAA;
    font-size: 14px;
    border-left: 1px dashed #999999;
}
.prod-search .select-prodsearch:active,
.prod-search .select-prodsearch:focus,
.prod-search input.input-prodsearch:active,
.prod-search input.input-prodsearch:focus {
    box-shadow: none;
    outline: none;
}
.product-categories {
    border: none;
    justify-content: center;
    max-width:1000px;
    margin: auto;
}

.profile-product-categories{
    margin-top:10px;
    max-width: 620px !important;
}

.product-categories.nav-tabs .nav-link {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: #242424;
    /* border: none; */
    /* border-bottom: 2px solid transparent; */
    margin: auto;
    padding: 0.5em;
    
    border: 1px solid #585858;
    border-radius: 15px;
    margin: 2px 3px
}
.product-categories.nav-tabs .nav-link.active {
    color: #fff;
    /* border: none; */
    border: 1px solid #1036CE !important;
    background-color: #1036CE !important;
}

.prod-list-single-div {
    margin-bottom: 30px;
    border: 1px solid #e2e2e2;
    padding: 1em;
    border-radius:16px;
    display: flex;
    flex-direction: column;
    min-height: 430px;
}
.prod-img-div {
    /* max-width: 285px; */
    width: 100%;
    height: 259px;
    margin-bottom: 15px;
    /* height: 400px; */
    display: flex;
    position: relative;
    flex-direction: column;
    filter: drop-shadow(0px 1px 3px #bdbdbd);
}

.prod-img-content{
    border-radius: 0px;
}
.prod-upper-text-div {
    display: flex;
    justify-content: space-between;

}
.prod-upper-text-div .price {
    font-size: 18px;
}
.prod-upper-text-div .price span {
    font-size: 14px;
}
.ul-pagination {
    display: flex;
    list-style-type: none;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.ul-pagination > li:not(:last-child) {
    margin-right: 10px;
}
.ul-pagination > li > a {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #AAAAAA;
    /* padding: 5px 10px; */
    background: #FFFFFF;
    border: 0.5px solid #AAAAAA;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ul-pagination > li.active > a,
.ul-pagination > li > a:hover {
    background: #FF5833;
    color: #FFFFFF;
    border: 1px solid #FF5833;
}
.ul-pagination > li.first > a:hover,
.ul-pagination > li.last > a:hover {
    background: #FFFFFF;
    border: 0.5px solid #FF5833;
}


/* all categories */
.all-cate-singgle-div {
    margin-bottom: 30px;
}
.allcate-img-div {
    width: 100%;
    height: 264px;
}

/* single lease (product) */
.leases-single-div {
    max-width: 1440px;
    /* border-top: 4px solid #1036CE; */
}
.carousel-lease-single-div .carousel.slide, 
.carousel-lease-single-div .carousel.slide .carousel-inner {
    height: 550px;
}
.carousel-lease-single-div .carousel.slide .carousel-inner .carousel-item, 
.carousel-lease-single-div .carousel.slide .carousel-inner .carousel-item > img {
    height: 100%;
    object-fit: contain;
}

.carousel-lease-single {
    position: absolute;
    bottom: 50px;
    right: 62px;
    display: flex;
}
.carousel-lease-single .btn-back,
.carousel-lease-single .btn-next {
    width: 56px;
    height: 56px;
    background: #FFFFFF;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.carousel-lease-single .btn-back {
    border: 0.5px solid #AAAAAA;
    box-shadow: 0px 0px 4px rgba(36, 36, 36, 0.12);
    margin-right: 25px;
}
.carousel-lease-single .btn-next {
    border: 1px solid #FF5833;
    box-shadow: 0px 0px 4px rgba(36, 36, 36, 0.12);
}

.lease-single-content-div {
    max-width: 520px;
    width: 100%;
    padding-top: 70px;
    margin: auto;
}
.lease-single-name {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lease-single-name > span {
    width: 45px;
    height: 45px;
    background: #FF5833;
    box-shadow: 0px 0px 4px rgba(36, 36, 36, 0.12);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lease-divider {
    border-bottom: 1px solid #AAAAAA;
    position: relative;
    width: 100%;
}
.lease-divider > span {
    display: block;
    width: 33px;
    height: 33px;
    border-radius: 100%;
    background: #AAAAAA;
    border: 10px solid #FFFFFF;
    position: absolute;
    top: -17px;
    margin-left: 0;
    margin-right: 0;
    right: 50%;
}

.lease-booking-issue-div{
    /* justify-content: space-between; */
    /* align-items: center; */
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.lease-single-owner-div {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
.ls-owner-img-div {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    overflow: hidden;
}
.chat-icon-div {
    width: 45px;
    height: 45px;
    background: #1036CE;
    box-shadow: 0px 0px 4px rgba(36, 36, 36, 0.12);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modalLeaseForm{
    width: 100%;
    /* margin-top: 20px; */
    padding-top: 3.5em
}

.modalLeaseFormButton{
    background-color: #999BA6;
    border: none;
}

.modal-body{
    background-color: #ffffff;
}

.modalChat .modal-body {
    padding: 20px 55px;
}

.modalIban{
    padding: 2em;
}

.modalSwift{
    padding: 1em;
}

.textarea-chat {
    border: 1px solid #AAAAAA;
    border-radius: 16px;
    color: #AAAAAA;
    font-size: 14px;
    padding-top: 20px;
    padding-left: 20px;
}
.textarea-chat::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #AAAAAA;
    opacity: 1; /* Firefox */
}
.textarea-chat :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #AAAAAA;
}
.textarea-chat::-ms-input-placeholder { /* Microsoft Edge */
    color: #AAAAAA;
}
.chat-auto-msg {
    background: #FFFFFF;
    border: 1px solid #FF5833;
    border-radius: 100px;
    color: #242424;
    text-align: center;
    padding: 10px 20px;
    margin-bottom: 15px;
    cursor: pointer;
}
.chat-auto-msg.active {
    background: #25D676;
    border-color: #25D676;
    color: #FFFFFF;
}
.btn-chat-submit {
    margin-top: 30px;
    margin-bottom: 25px;
    background: #FF5833;
    border-radius: 16px;
    border: none;
    font-weight: 500;
    color: #FFFFFF;
    padding: 14px 70px;
}
.btn-chat-submit.disabled,
.btn-chat-submit:disabled {
    background: #AAAAAA;
    color: #FFFFFF;
}
.btn-chat-submit:focus, 
.btn-chat-submit:active, 
.btn-chat-submit:hover {
    background: #db4f30;
    color: #FFFFFF;
}


/* lease form */

.form-check input{
    margin-right: 10px;
}

.btn-payment {
    background: #FF5833;
    border-radius: 10px;
    color: #FFFFFF;
    padding: 11px 50px;
    width: 100%;
    margin-top: 60px;
}
.btn-payment.disabled,
.btn-payment:disabled {
    background: #AAAAAA;
    color: #FFFFFF;
}
.select-time-toggle {
    position: relative;
}
.select-time-toggle .label1 {
    position: absolute;
    left: 0;
}
.select-time-toggle .form-check-input {
    margin-left: 0px;
    margin-right: 12px;
    height: 16px;

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-color: #838383;
    border-color: #c5c5c5;
}
.select-time-toggle .form-check-input:checked {
    background-color: #FF5833;
    border-color: #FF5833;
}
.time-list-wrapper {
    width: 74%;
    margin: 0 auto;
    margin-top: 25px;
}
.time-list-per-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.time-list-per-div > span:first-child {
    margin-right: 15px;
}
.time-list-per-div > span {
    display: block;
    background: #FFFFFF;
    border: 2px solid #AAAAAA;
    color: #AAAAAA;
    text-align: center;
    padding:7px 20px;
    width: 100%;
    cursor: pointer;
}
.time-list-per-div > span.active {
    background: #FF5833;
    color: #FFFFFF;
    border-color: #FF5833;
}
.btn-continue-time {
    font-weight: 700;
    border-radius: 16px;
}

/* lease payment */
.leases-payment-wrapper {
    background: #ffffff;
    padding-bottom: 20px;
}
.pm-outer-div {
    border: 1px solid #c2c2c2;
    padding: 35px;
    background: #FFFFFF;
}
.pm-inner-div {
    border: 1px solid #AAAAAA;
    border-radius: 16px;
    padding: 28px;
    background: #FFFFFF;
}
.divider-dashed {
    border-bottom: 1px dashed #AAAAAA;
}
.discount-div {
    position: relative;
    display: flex;
}
.discount-div > input {
    border: 1px solid #AAAAAA;
    border-radius: 16px 0px 0px 16px;
    color: #AAAAAA;
    height: 45px;
    padding-left: 18px;
}
.discount-div > button {
    background: #FF5833;
    border-radius: 0px 16px 16px 0px;
    font-weight: 700;
    color: #FFFFFF;
    padding: 5px 50px;
}
.discount-div > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #AAAAAA;
    opacity: 1; /* Firefox */
}
.discount-div > input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #AAAAAA;
}
.discount-div > input::-ms-input-placeholder { /* Microsoft Edge */
    color: #AAAAAA;
}
.pm-right-div {
    background: #FFFFFF;
    border: 1px solid #c2c2c2;
    padding: 35px;
}
.btn-back {
    background: #FFFFFF;
    border: 1px solid #FF5833;
    border-radius: 10px;
    padding: 11px 50px;
    color: #242424;
}


/* my leases */
.sort-div {
    /* display: flex; */
    align-items: center;
}
.sort-div .dropdown {
    margin-bottom: 20px;
}
.sort-div .dropdown button {
    width: 100%;
}
.search-div {
    position: relative;
    margin-top: 20px;
}
.search-div > svg {
    cursor: pointer;
    position: absolute;
    z-index: 9;
    top: 11px;
    right: 14px;
}
.search-div > input {
    border: 1px solid #AAAAAA;
    border-radius: 10px;
}
.sort-dropdown {
    background: #F5F5F5;
    border-radius: 10px;
    color: #242424;
    border-color: #F5F5F5;
}
.sort-dropdown:hover{
    background: #FF5833;
    border-color: #ff886e;
    color: white;
}
.sort-dropdown:focus{
    background: rgba(255,88,51) !important;
    color: white !important;
    box-shadow: 0px 0px 0px 3px #ff886e;
    border-color: #ff886e;
}
.chat-icon-div.in-img {
    position: absolute;
    bottom: 18px;
    right: 18px;
}
.span-tag {
    box-shadow: 0px 0px 8px rgba(24, 24, 24, 0.08);
    border-radius: 100px;
    padding: 4px 18px;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 12px;
}
.span-tag.pending {
    background: #FFC700;
}
.span-tag.active {
    background: #0C7CFF;
}
.span-tag.complete {
    background: #02CF3B;
}
.span-tag.cancel {
    background: #cf3202;
}

/* my lease single */

.div-mtop{
    margin-top: 70px;
}

.div-button-active{
    max-width: 520px;
    margin: auto;
}

.term-outer-div {
    max-width: 520px;
    border: 1px solid #FF5833;
    border-radius: 16px;
    padding: 25px;
    margin: auto;
}
.text-border-orange {
    border-bottom: 1px solid #FF5833;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.btn-post-review {
    background: #FF5833;
    border-radius: 0;
    border: none;
    font-weight: 500;
    color: #FFFFFF;
    padding: 16px 10px;
}
.btn-post-review:hover,
.btn-post-review:active,
.btn-post-review:focus {
    background: #ff441a;
    color: #FFFFFF;
}
.btn-rated-already {
    background: #25D676;
    border-radius: 12px;
    border: none;
    font-weight: 500;
    color: #FFFFFF;
    padding: 16px 10px;
}
.btn-rated-already:hover,
.btn-rated-already:active,
.btn-rated-already:focus {
    background: #25D676;
    color: #FFFFFF;
}
.btn-cancel-lease {
    background: #fb1e1e;
    border-radius: 12px;
    border: 1px solid #fc0000;
    font-weight: 300;
    color: #ffffff;
    padding: 16px 10px;
    max-width: 207px;
}

.btn-cancel-lease-lessor {
    background: #1036CE;
    border-radius: 12px;
    border: 1px solid #3d64ff;
    font-weight: 300;
    color: #ffffff;
    padding: 16px 10px;
    max-width: 207px;
}

.btn-not-returned-lease {
    background: #fb1e1e;
    border-radius: 12px;
    border: 1px solid #fc0000;
    font-weight: 300;
    color: #ffffff;
    padding: 16px 10px;
}

/* notifications */
.noti-div {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 60px;
}
.noti-per-div {
    display: flex;
    border: 1px solid #C4C4C4;
    box-shadow: 0px 4px 10px rgba(220, 220, 220, 0.25);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
}
.noti-img {
    max-width: 180px;
    width: 100%;
    /* height: 160px; */
    border-radius: 10px;
    overflow: hidden;
}
.noti-desc {
    padding-left: 20px;
    position: relative;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 50px;

}
.noti-bell {
    position: absolute;
    top: 0;
    right: 0;
    background: #FF5833;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.noti-chat {
    position: absolute;
    top: 0;
    right: 0;
    background: #1036CE;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


/* chat */
.search-chat-div {
    position: relative;
    max-width: 500px;
    width: 100%;
}
.search-chat-div > input {
    border: 1px solid #AAAAAA;
    color: #AAAAAA;
    padding-left: 40px;
    height: 36px;
    font-size: 14px;
}

.left-chat {
    max-height: 300px;
    /* height: 100%; */
    overflow-y: scroll;
    border: 1px solid #AAAAAA;
    background: #F6F6F6;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 10px 10px 0px 10px;
}

.chat-wrapper-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.chat-wrapper-div > .left > .left-top {
    padding-left: 50px;
    padding-right: 20px;
    padding-bottom: 66px;
}

.chat-wrapper-div > .right {
    width: 100%;
}

.chatlist{
    width: 100%;
    padding: 20px;
}

.chat-msg-left-div {
    background: #F1F1F1;
    display: flex;
    margin: auto;
    cursor: pointer;
    margin-bottom: 3px;
}
.new-msg-span {
    font-weight: 700;
    color: #FFFFFF;
    background: #1976D2;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 0;
}
.new-msg-span.seen {
    background: #E0E0E0;
}

.right-chat > .right-top {
    padding: 20px;
    border-bottom: 1px solid #AAAAAA;
}
.msg-content-div {
    padding-left: 30px;
    padding-right: 30px;
    max-height: 500px;
    /* height: 100%; */
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
}
.sender-div {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.sender-msg-div {
    background: #1036CE;
    border-radius: 8px 8px 2px 8px;
    text-align: center;
    padding: 10px;
    color: #FFFFFF;
    font-size: 14px;
}
.receiver-div {
    display: flex;
    justify-content: start;
    margin-bottom: 10px;
}
.receiver-msg-div {
    background: #686e85;
    border-radius: 8px 8px 8px 2px;
    text-align: center;
    padding: 10px;
    color: #ffffff;
    font-size: 14px;
}
.chat-img {
    width: 250px;
    width: 200px;
    border-radius: 10px;
    overflow: hidden;
}

.right-bottom{
    display: flex;
    align-items: center;
}

.chat-wrapper-div > .right > .right-bottom {
    border-top: 1px solid #1036CE;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
}
.chat-input-div {
    position: relative;
    width: 100%;
    padding: 10px;
}
.chat-input-div > input {
    border: 1px solid #AAAAAA;
    border-radius: 10px;
    height: 60px;
    padding-left: 20px;
    padding-right: 100px;
}
.btn-chat-send {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #FF5833;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
}
.btn-chat-send:hover,
.btn-chat-send:active,
.btn-chat-send:focus {
    background: #FF5833;
    border-color: #FF5833;
}
.chat-features-div {
    position: absolute;
    top: 18px;
    margin-right: 15px;
    right: 0;
    display: flex;
    align-items: center;
    margin-top: 12px;
}
.chat-features-div > svg {
    cursor: pointer;
    margin-right: 10px;
}


/* lessor profile */
.lessor-profile-top-div {
    background: #1036CE;
    padding-top: 50px;
    padding-bottom: 20px;
    position: relative;
}

.lessor-profile-img-div-lessee{
    max-width: 80px;
    margin-top: 0px;
    width: 100%;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
}

.lessor-profile-img-div {
    max-width: 80px;
    margin-top: 95px;
    width: 100%;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
}
.lessor-name-div {
    width: 100%;
    padding-top: 125px;
    padding-left: 20px;
}


.lessor-profile-btn-div {
    margin-top: 50px;
}

.lessor-profile-btn-div .btn-cancel-lease .btn-not-returned-lease{
    font-weight: 700;
}

.btn-not-returned-lease{
    background-color: #da3434;
}
.btn-not-returned-lease:hover{
    background: #ff8484;
    color: #fbfbfb;
}

.btn-not-returned-lease:hover{
    background: #ffa2a2;
    color: #ffffff;
}

.btn-cancel-lease:hover{
    background: #ff8484;
    color: #ffffff;
}

.btn-cancel-lease-lessor:hover{
    background: #96abff;
    color: #ffffff;
}

.lessor-profile-btn-div .btn-not-returned-lease:hover,
.lessor-profile-btn-div .btn-not-returned-lease:active,
.lessor-profile-btn-div .btn-not-returned-lease:focus {
    background: #a2d0ff;
    color: #FF5833;
}

.lessor-profile-btn-div .btn-cancel-lease:hover,
.lessor-profile-btn-div .btn-cancel-lease:active,
.lessor-profile-btn-div .btn-cancel-lease:focus {
    background: #ffa2a2;
    color: #FF5833;
}

.review-div {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 14px rgba(222, 222, 222, 0.25);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;

}
.review-img-div {
    max-width: 120px;
    width: 100%;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 20px;
}

/* .review-img-div:lang(ar) {
    max-width: 120px;
    width: 100%;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    margin-left: 20px;
} */

.review-content-div {
    width: 100%;
    /* margin-left: 20px; */
}
.review-post-img-div:lang(en) {
    max-width: 120px;
    width: 100%;
    height: 190px;
    border-radius: 10px;
    overflow: hidden;
    margin: 0px 20px 0px 0px;
}

.review-post-img-div:lang(ar) {
    max-width: 120px;
    width: 100%;
    height: 190px;
    border-radius: 10px;
    overflow: hidden;
    margin: 0px 0px 0px 20px;
}

.modal-review-div{
    padding: 25px;
}
.modal-review-img-div {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;

}
.lease-divider-light {
    border-bottom: 1px solid #AAAAAA;
    position: relative;
    width: 100%;
}
.lease-divider-light > span {
    display: block;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid #AAAAAA;
    position: absolute;
    top: -9px;
    margin-left: 0;
    margin-right: 0;
    right: 50%;
    box-shadow: 0px 0px 0px 6px #ffffff;
}
.review-rate-div {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
}
.review-stars {
    max-width: 280px;
    width: 100%;
    display: block;
    margin: 0 auto;
}
.review-stars svg {
    cursor: pointer;
}
.review-stars svg.star-svg {
    fill: #FCE412;
}
.review-stars svg:not(:last-child) {
    margin-right: 5px;
}


/* settings */
.settings-div {
    background: #F7F7F7;
    padding-top: 60px;
    padding-bottom: 50px;
}
.setting-left-top {
    border-bottom: 1px solid #1036CE;
    padding: 20px;
    margin-bottom: 20px;
}

.settings-div .main-setting-nav .nav-item .nav-link {
    color: #1036CE;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.settings-div .main-setting-nav .nav-item .nav-link svg {
    margin-right: 15px;
}
.settings-div .main-setting-nav .nav-item .nav-link.active {
    background: #FF5833;
    color: #ffffff;
}
.settings-div .main-setting-nav .nav-item .nav-link.active svg path {
    fill: #ffffff;
}


/* contact us separate */
.profhomecon {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #F7F7F7;
}


/* profile */
.my-profile-div {
    background: #FFFFFF;
    border: 2px solid #AAAAAA;
    border-radius: 10px;
    padding: 20px;
}
.my-profile-div.homecon {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}
.btn-save-changes {
    background: transparent;
    color: #FF5833;
    font-size: 15px;
}
.myprof-img-div {
    width: 60px;
    height: 60px;
    border: 1px solid #AAAAAA;
    border-radius: 100%;
    overflow: hidden;
}
.myprof-img-div + .btn-change-photo {
    width: 30px;
    height: 30px;
    right: -9px;
    bottom: -9px;
}
.div-2px {
    border-bottom: 2px solid #D1D1D1;
}
.div-2px.blue {
    border-color: #1036CE;
}
.profile-form-div {
    max-width: 550px;
    width: 100%;
    
}
.prof-per-div {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.prof-per-div-iban{
    display: flex;
    flex-direction: row-reverse;
}

.prof-per-div-input{
    text-align: left !important;
}

.prof-per-div > .left {
    width: 40%;
    text-align: right;
    padding-right: 50px;
}
.prof-per-div > .right {
    width: 60%;
    position: relative;
}
.prof-per-div > .right input,
.prof-per-div > .right select,
.prof-per-div > .right textarea {
    background: #F4F4F4;
    border-radius: 10px;
    border: none;
}
.prof-per-div > .right .setnow {
    position: absolute;
    top: 6px;
    right: 15px;
    font-size: 14px;
    color: #FF5833;
    cursor: pointer;
}
.update-pin {
    color: #FF5833;
    cursor: pointer;
    font-size: 14px;
    float: right;
}
.prof-per-div > .right .setnow.pin {
    font-size: 12px;
    top: 9px;
}

.main-tab-content.tab-content .product-categories.nav-tabs .nav-link.active {
    background: transparent;
}

/* invoices */
.invoice-per-div {
    border: 1px solid #AAAAAA;
    border-radius: 10px;
    background: #FFFFFF;
    padding: 10px 20px 10px 20px;
    margin-bottom: 20px;
}
.invoice-detail-div {
    border-top: 1px solid #AAAAAA;
    padding-top: 10px;
}


/* notifications */
.notification-per-div {
    display: flex;
    justify-content: space-between;
}
.notification-per-div.border-bot {
    border-bottom: 1px solid #AAAAAA;
}


/* contact us */
.contact-us-div {
    max-width: 500px;
    width: 100%;

}
.contactus-form {
    font-size: 14px;
    color: #242424;
    border: 1px solid #AAAAAA;
    border-radius: 8px;
    height: 45px;
    margin-bottom: 20px;
}
.contactus-form.textarea {
    height: auto;
}
.btn-submit-contactus {
    background: #FF5833;
    border-radius: 16px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    padding: 15px 120px;
    margin-bottom: 20px;
}
.contactus-social-icons svg {
    /* margin-right: 15px; */
    align-items: center;
}

.contactus-social-icons a{
    margin-right: 15px;
    /* align-items: center; */
}


/* my lessor profile */
.thisqr{
 background-color: white;
 font-size:90px;
 font-weight: 900;
 margin: 0;
 max-height: 900px;
}

.thisqr-lessor{
    color: white;
    position: absolute;
    right: 0;
    margin-right: 20px;
    top: 0;
    margin-top: 20px;
    max-height: 170px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
}

.thisqr-lessor:lang(ar){
    color: white;
    position: absolute;
    left: 0;
    margin-left: 20px;
    top: 0;
    margin-top: 20px;
    max-height: 170px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
}


.displayqr{
    color:#000000;
    font-size:14px;
    font-weight: 200;
    border: 1px solid #c2c2c2;
    padding: 20px;
    max-width: 320px;
    margin: 0 auto;
    margin-top: 20px;
}

.qrbuttons{
    display: flex;
    justify-content: center;
    align-content: center;
}

.downloadbutton {
    padding: 10px;
    background-color: #1036CE;
    color: white;
    border-radius: 6px;
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: pointer;
}
.downloadbutton p{
    margin: 0;
}

.qr-box{
    display: flex;
    justify-content: center;
    height: 360px;
}

.qr-container {
    z-index: 9999;
    display: flex;
    justify-content: center;
    height: auto;
    flex-direction: column;
}

.delete-icon-div {
    width: 42px;
    height: 42px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(36, 36, 36, 0.12);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: 18px;
    right: 127px;
    /* right: 72px; */
}

.generateqr-icon-div{
    width: 42px;
    height: 42px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(36, 36, 36, 0.12);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: 18px;
    right: 72px;
}

.edit-icon-div {
    width: 42px;
    height: 42px;
    background: #1036CE;
    box-shadow: 0px 0px 4px rgba(36, 36, 36, 0.12);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: 18px;
    right: 18px;
}


.add-post-div {
    /* border: 2px dashed #FF5833; */
    width: 250px;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #ffffff;
    background-color: #FF5833;
}

/*Add Post Deposit Field and Info*/
.deposit-info-toggle{
    position: absolute;
    width: 200px;
    top:0;
    transform: translateX(25px);
    background: #e5e5e5;
    box-shadow: 0px 0px 6px rgba(36, 36, 36, 0.12);
    text-align: center;
    padding: 1em;
    z-index: 999;
}

.hidden{
    visibility:hidden !important;
}

.deposit-info-toggle-ar{
    position: absolute;
    width: 200px;
    top:0;
    transform: translateX(-25px);
    background: #e5e5e5;
    box-shadow: 0px 0px 6px rgba(36, 36, 36, 0.12);
    text-align: center;
    padding: 1em;
    z-index: 999;
    /* border: solid 1px #c5c5c5; */
}

.fixed-info-toggle{
    position: absolute;
    width: 200px;
    /* top:0; */
    margin-top: -15px;
    transform: translateX(25px);
    background: #e5e5e5;
    box-shadow: 0px 0px 6px rgba(36, 36, 36, 0.12);
    text-align: center;
    padding: 1em;
    z-index: 999;
}


.fixed-info-toggle-ar{
    position: absolute;
    width: 200px;
    /* top:0; */
    transform: translateX(-25px);
    background: #e5e5e5;
    box-shadow: 0px 0px 6px rgba(36, 36, 36, 0.12);
    text-align: center;
    padding: 1em;
    z-index: 999;
    /* border: solid 1px #c5c5c5; */
}

.leases-info-toggle{
    position: absolute;
    width: 200px;
    /* top:0; */
    margin-top: -15px;
    transform: translateX(-205px);
    background: #e5e5e5;
    box-shadow: 0px 0px 6px rgba(36, 36, 36, 0.12);
    text-align: center;
    padding: 1em;
    z-index: 999;
}

.leases-info-toggle-ar{
    position: absolute;
    width: 200px;
    /* top:0; */
    transform: translateX(205px);
    background: #e5e5e5;
    box-shadow: 0px 0px 6px rgba(36, 36, 36, 0.12);
    text-align: center;
    padding: 1em;
    z-index: 999;
    /* border: solid 1px #c5c5c5; */
}

/* edit post */
.editpostForm {
    color: #212529;
    font-size: 14px;
    /* background: transparent; */
    border: 1px solid #C9C9C9;
    border-radius: 4px;
    height: 46px;
    margin-bottom: 30px;
}

.editpostForm::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #C9C9C9;
    opacity: 1; /* Firefox */
}
.editpostForm:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #C9C9C9;
}
.editpostForm::-ms-input-placeholder { /* Microsoft Edge */
    color: #C9C9C9;
}
.editpostForm:active,
.editpostForm:focus {
    border: 1px solid #FFFFFF;
    outline: none;
    box-shadow: 0px 2px 8px rgba(24, 24, 24, 0.24);
    border-radius: 4px;
}
.editpostFormTextarea {
    color: #C9C9C9;
    font-size: 14px;
    border: 1px solid #C9C9C9;
    border-radius: 4px;
    margin-bottom: 30px;
}
.editpostFormTextarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #C9C9C9;
    opacity: 1; /* Firefox */
}
.editpostFormTextarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #C9C9C9;
}
.editpostFormTextarea::-ms-input-placeholder { /* Microsoft Edge */
    color: #C9C9C9;
}

.claimContainer {
    font-size: 14px;
    border: 1px solid #C9C9C9;
    border-radius: 4px;
    height: 46px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #bbbbbd;
    color: #ffffff;
}

.claimBG{
    background-color: #1036CE;
}

.swiftContainer{
    max-height: 450px;
    overflow: auto;
    margin: 0 auto;

}

.mytext{
    margin: auto;
    margin-left: 0 !important;
    text-align: left;
}

.swiftlist{
    display: flex;
    border: solid 1px #d2d2d2;
    padding: 0.5em;
    margin: 0 auto;
    align-content: center;
    max-width: 400px;
    cursor: pointer;
}

.swiftlist:hover{
    background-color: rgba(12,124,255,0.6);
    color: white;
    transition:200ms;
}

.swiftselect input{
    /* width: auto; */
}

.swiftbanklogo{
    width: 50px;
    height: auto;
    margin-right: 5px;
    padding: 5px;
}

.swiftbanklogoselect{
    width: 40px;
    height: auto;
    margin-right: 30px;
    /* padding-right: 10px; */
}


.swiftlogo-container{
    padding-left: 20px;
    margin: auto;
}

.upload-img-post-div {
    /* display: block; */
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 40px;
}
.upload-col {
    margin-bottom: 20px;
}
.delete-icon-img {
    cursor: pointer;
    position: absolute;
    top: -9px;
    right: auto;
    left: 151px;
}
.per-upload-img {
    width: 160px;
    height: 140px;
    border-radius: 10px;
    overflow: hidden;
    /* margin-right: 35px; */
}
.btn-upload-images {
    width: 160px;
    height: 140px;
    border: 1px dashed #999BA6;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-save-post {
    background: #FF5833;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 90px;
}

.cursorPointer {
    cursor: pointer;
}

.btn-save-iban{
    background: #FF5833;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #ff967f;
}

/* lessor lease */
.lessor-lease-div {
    display: flex;
    /* align-self: 'center'; */
    justify-content: center;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 14px rgba(222, 222, 222, 0.25);
    /* padding: 20px; */
    margin-bottom: 15px;
    border-radius: 20px;
}
.lessor-lease-div .col1 {
    width: 25%;
}
.lessor-lease-div .col2 {
    width: 60%;
}
.lessor-lease-div .col3 {
    width: 15%;
}
.lesle-img-div {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
}

.lessor-lease-div-left:lang(en){
    /* position: relative; */
    /* margin: 0px 10px 0px 10px; */
    padding: 10px;
    display: flex;
    width: 50%;
    justify-content: flex-end;
}
.lessor-lease-div-left:lang(ar){
    /* position: relative; */
    /* margin: 0px 10px 0px 10px; */
    padding: 10px;
    display: flex;
    width: 50%;
    justify-content: flex-start;
}

.lessor-lease-div-right{
    margin: 0px 10px 0px 10px;
    padding: 10px;
    width: 50%;
}

.lease-prod-img-div{
    /* width: 150px; */
    /* max-width: 150px; */
    overflow: hidden;
    position: relative;
    border-radius: 18px;
    max-height: 400px;
}

.lessor-lease-div-right-name{
    align-items: center;
    justify-content: start;
}

.lessor-lease-div-status{
    position:absolute;
    z-index: 9999;
    top:0;
    margin-left: 5px;
    margin-top: -10px;
}

.lessor-lease-div-status:lang(ar){
    position:absolute;
    z-index: 9999;
    top:0;
    margin-right: 5px;
    margin-top: -10px;
}

.lessor-lease-single-div .lease-single-name span.map {
    width: 45px;
    height: 45px;
    background: #FF5833;
    box-shadow: 0px 0px 4px rgb(36 36 36 / 12%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lessor-lease-single-div .lease-single-name .span-tag {
    height: 28px;
    margin-right: 20px;
}

.custom-input:hover{
    background-color: #0f2ba6;
}


/* earnings */
.earnings-inner-div {
    border: 1px solid #1036CE;
    border-radius: 10px;
    padding: 20px;
}
.earning-amount-div {
    background: #1036CE;
    border-radius: 10px;
    padding: 10px;
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
    margin-bottom: 25px;
}
.earnings-whole-div {
    border: 1px solid #FF5833;
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 20px;
}



/* loading screen */
.wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
.wrap .text {
    color: #1036CE;
    display: inline-block;
    margin-left: 5px;
}
  
.bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: 15px;
}
.bounceball::before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #1036CE;
      transform-origin: 50%;
      animation: bounce 500ms alternate infinite ease;
}
  
@keyframes bounce {
    0% {
      top: 30px;
      height: 5px;
      border-radius: 60px 60px 20px 20px;
      transform: scaleX(2);
    }
    35% {
      height: 15px;
      border-radius: 50%;
      transform: scaleX(1);
    }
    100% {
      top: 0;
    }
}

/* map */
.modal-map {
    max-width: 600px;
    width: 100%;
    /* height: 500px; */
    padding: 0;
}
.map-modal-div {
    max-width: 600px;
    width: 100%;
    height: 350px;
    position: relative;
}
.modal-map-wrapper .modal-content {
    max-width: 602px;
    width: 100%;
    display: flex;
    text-align: center;
}

/* maximum lease term reached modal */
.modal-lease-term-wrapper .modal-content{
    margin-top: 50%;
    max-width: 442px;
    height: 210px;
    padding: 1.5em;
    width: 100%;
    text-align: center;
}

/* loading posts */
.loader {
    margin: auto;
    border: 8px solid #EAF0F6;
    border-radius: 50%;
    border-top: 8px solid #1036CE;
    width: 60px;
    height: 60px;
    animation: spinner 1s linear infinite;
}
@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.leases-date-form .rmdp-container {
    display:block !important;
}
.time-list-wrapper.lease-form {
    width: 100%;
    display: inline-block;
    grid-gap: 5px;
    gap: 5px;
}
.timebox {
    display: inline-block;
    background: #FFFFFF;
    border: 2px solid #AAAAAA;
    color: #AAAAAA;
    text-align: center;
    padding: 7px 20px;
    width: 95px;
    cursor: pointer;
}
.timebox.active {
    background: #FF5833;
    color: #FFFFFF;
    border-color: #FF5833;
}

.filter-div .limit-h .dropdown-menu.show {
    height: 350px;
    overflow-y: scroll;
}


/* support */
.sup-div {
    padding-top: 60px;
    padding-bottom: 50px;
    background: #1036CE;
}
.ques-div {
    padding-top: 60px;
    padding-bottom: 60px;
}
.ques-content {
    max-width: 1050px;
    width: 100%;
    margin: 0 auto;
}
.ques-top-each-div {
    background: #FAFAFA;
    border: 1px solid #CACACA;
    border-radius: 10px; 

    font-family: 'IBM Plex Sans Arabic';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #242424;
    padding: 10px 15px;
    margin-bottom: 20px;
}

.input-group{
    display: inline-block;
}

.upper-part-swiftcode{
    justify-content: end;
}

.visapaymentLogo{
    height: 30px;
    margin-left: 10px;
}

.visapaymentLogo:lang(ar){
    height: 30px;
    margin-right: 10px;
}

.paymentLogo{
    margin-left: 10px;
    height: 20px;
}

.paymentLogo:lang(ar){
    margin-right: 10px;
    height: 20px;
}

.mada{
    height: 15px;
}
/* arabic */
.ls-owner-img-div:lang(ar) {
    margin-left: 1em;
}
.div-head-profpic:lang(ar) {
    margin-right: 1em;
}
.leases-date-form .rmdp-container + svg:lang(ar),
.leaseform-time > svg:lang(ar) {
    right: auto !important;
    left: 20px !important;
}
.discount-div:lang(ar) {
    direction: initial;
}
.iban-grp:lang(ar) {
    direction: initial;
}

.iban-grp{
    display: flex;
    width: 100%;
}

.iban-grp-payment-form{
    display: inline-block;
    width: 100%;
}

.iban-grp-input{
    border: 1px solid #c2c2c2 !important;
}

.search-div > svg:lang(ar) {
    cursor: pointer;
    position: absolute;
    z-index: 9;
    top: 11px;
    left: 14px;
    right: auto;
}
.carousel-lease-single:lang(ar) {
    direction: initial;
}
.carousel-custombtn-div.banner:lang(ar) {
    direction: initial;
}

.ul-pagination > li:lang(ar) {
    margin-right: 10px;
}

.contact-sup-div {
    background: #FEFEFE;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
}

.search-city-selection{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    padding: 20px;
    z-index: 9999;
    background: #FEFEFE;
    position: relative;
}

.search-city-selection{
    width: 100%;
}

.right-chat{
    border: 1px solid #AAAAAA;
    margin: 10px;
}

.lessor-name-div:lang(ar) {
    padding-right: 20px;
    padding-top: 125px;
    
}

.lessor-name-div-lessee{
    margin-left: 20px;
}

.lessor-name-div-lessee:lang(ar) {
    padding-right: 20px;
    margin-right: 10px;
}

.resendContainer{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.storeLogos{
    width: 140px;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
}

.storeLogosContainer{
    margin-bottom: 20px;
}
/*****************************************
    DESKTOP  V I E W
******************************************/
@media (min-width: 1024px) {

    .lessor-name-div-lessee:lang(ar) {
        padding-right: 200px;
        padding-left: 0px;
    }

    .btn-post-review {
        margin-top: 100px;
        background: #FF5833;
        border-radius: 0;
        border: none;
        font-weight: 500;
        color: #FFFFFF;
        padding: 16px 10px;
    }

    /* header */
    .header-div .navbar.navbar-expand-lg {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .header-div #basic-navbar-nav .navbar-nav {
        margin-left: 80px;
    }

    /* footer */
    .footer-up-div {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .footer-logo-img {
        margin-top: 30px;
    }


    /* homepage */
    .banner-text-div {
        padding-right: 30px;
        display: flex;
        justify-content: end;
        float: right;
    }
    .banner-img-slide-div .carousel.slide,
    .banner-img-slide-div .carousel.slide .carousel-inner {
        height: 530px;
    }
    .services-main-div {
        padding-top: 150px;
        padding-bottom: 150px;
    }
    .services-div {
        display: flex;
    }
    .serv-per-div {
        margin-right: 15px;
        width: 31%;
    }


    /* login */
    .login-content-div {
        padding-left: 100px;
        padding-right: 90px;
    }

    
    /* verification */
    .veri-send-text {
        width: 28%;
        margin: 0 auto;
    }

    .resendContainer{
        margin: 0 auto;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }


    /* registration */
    .reg-input-form-div {
        padding-left: 60px;
    }


    /* single lease (product) */
    .carousel-lease-single-div .carousel.slide, 
    .carousel-lease-single-div .carousel.slide .carousel-inner {
        height: 820px;
    }
    .lease-single-content-div {
        padding-left: 50px;
    }


    /* my leases */
    .filter-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .search-div {
        margin-top: 0;
    }
    .search-div > input {
        width: 280px;
    }

    .div-mtop{
        margin-top: 70px;
    }

    /* my lease single */
    .term-outer-div {
        max-width: 520px;
        margin-top: 70px;
    }

    /* chat */
    .chat-wrapper-div {
        display: flex;
        margin: auto;
        max-width: 1440px;
        padding: 20px;
        min-height: 600px;
        flex-direction: row;
    }
    .left-chat {
        /* height: 100%; */
        max-height: 670px;
        overflow-y: scroll;
        width: 30%;
        border: 1px solid #AAAAAA;
        padding: 0;
        margin: 10px 0px 10px 10px;
    }
    .right-chat {
        width: 60%;
        border: 1px solid #AAAAAA;
        padding-bottom: 20px;
    }
    .msg-content-div {
        padding-left: 20px;
        padding-right: 20px;
    }
    .chat-wrapper-div > .right > .right-bottom {
        padding-left: 80px;
        padding-right: 80px;
    }


    /* lessor profile */
    .lessor-profile-top-div {
        padding-top: 0px;
    }

    .lessor-profile-img-div {
        max-width: 180px;
        height: 180px;
        position: absolute;
        bottom: -80px;
    }

    .lessor-profile-img-div-lessee {
        max-width: 180px;
        height: 180px;
        position: absolute;
        bottom: -80px;
    }
    
    .lessor-name-div-lessee{
        margin-left: 0px;
        padding-left: 210px;
        padding-top: 90px;
        
    }

    .lessor-name-div {
        padding-left: 210px;
        padding-top: 110px;
    }

    .lessor-profile-btn-div {
        margin-top: 0;
    }
    .product-categories.lessor-tabs {
        padding-left: 115px;
    }

    .lessor-tab{
       max-width: 800px;
    }

    /* settings */
    .settings-div .tab-content.main-tab-content {
        padding-left: 40px;
    }

    
    /* leases */
    .lessor-lease-div {
        display: flex;
    }


    /* my leases */
    .sort-div {
        display: flex;
    }
    .sort-div .dropdown {
        margin-bottom: 0;
    }
    .sort-div .dropdown button {
        width: auto;
    }


    /* earnings */
    .earnings-div {
        width: 50%;
    }

    /* adjustments */
    .btn-verification {
        padding: 11px 180px;
    }
    .ser-pro-desc-div {
        padding: 110px 56px 80px 73px;
    }


    /* edit post */
    .upload-img-post-div {
        /* display: flex; */
        grid-template-columns: auto auto auto auto auto auto;
    }
    /* .delete-icon-img {
        right: 27px;
        left: auto;
    } */

    .thisqr-lessor{
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
    }

    .thisqr-lessor:lang(ar){
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
    }
    
    /* arabic */
    .lessor-name-div:lang(ar) {
        padding-left: 0;
        padding-right: 210px;
    }
    /* .product-categories.lessor-tabs:lang(ar) {
        padding-left: 0;
        padding-right: 215px;
    } */
}

@media (max-width: 1224px) {
    .product-categories{
        /* margin-left: 20px; */
    }
    .lessor-tab{
        max-width: 700px;
     }
}

@media (max-width: 1090px) {
    /* .product-categories{
        margin-left: 40px;
    } */

    .lessor-tab{
        max-width: 500px;
     }
}

.mycalendar_calendarcontainer__2MLBB{
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
    /* margin: auto; */
}

.mycalendar_dateAvail__1kCst{
    margin-left: -120px;
}

.mycalendar_calendarhead__2XKPf{
    max-width: 350px;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0px;
}
.mycalendar_calendarhead__2XKPf p{
    margin: 0;
    font-weight: bold;
    font-size:15px;
}

.mycalendar_daysContainer__3rR6L th{
    padding: 5px;
}

.mycalendar_myDates__2BHpZ{
    padding: 10px;
}

.mycalendar_myDates__2BHpZ a{ 
    font-weight: bold;
    text-decoration: none !important;;
}

.mycalendar_availDays__2A33x{
    cursor: pointer;
    text-align: center;
    color: #5e5c5f;

}

.mycalendar_extraDays__ePMLn{
    cursor: default;
    background-color: transparent;
    pointer-events: none;
}

.mycalendar_selectedDate__1s3at{
    background-color: #1136cd;
}
.mycalendar_selectedDate__1s3at a{
    color: #ffffff;
}

.mycalendar_otherExtraDays__o0LIJ{
    pointer-events: none;
    color:rgb(197, 197, 197);
}


.mycalendar_calendarCol__326_C{
    text-align: center;
}
.monthlycalendar_calendarcontainer__3opAY{
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
    /* margin: auto; */
}

.monthlycalendar_dateAvail___QJc9{
    margin-left: -120px;
}

.monthlycalendar_calendarhead__2NRW2{
    max-width: 350px;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0px;
}
.monthlycalendar_calendarhead__2NRW2 p{
    margin: 0;
    font-weight: bold;
    font-size:15px;
}

.monthlycalendar_daysContainer__19HG_ th{
    padding: 5px;
}

.monthlycalendar_myDates__rg_6V{
    padding: 10px;
}

.monthlycalendar_myDates__rg_6V a{ 
    font-weight: bold;
    text-decoration: none !important;;
}

.monthlycalendar_availDays__2uI8Y{
    cursor: pointer;
    text-align: center;
    color: #5e5c5f;

}

.monthlycalendar_extraDays__17w0N{
    cursor: default;
    background-color: transparent;
    pointer-events: none;
}

.monthlycalendar_selectedDate__2Gddn{
    background-color: #1136cd;
}
.monthlycalendar_selectedDate__2Gddn a{
    color: #ffffff;
}

.monthlycalendar_otherExtraDays__3cAR-{
    pointer-events: none;
    color:rgb(197, 197, 197);
}


.monthlycalendar_calendarCol__2jpOx{
    text-align: center;
}
